import React from "react"
import { useWpAbout } from "../hooks/useWpAbout"
import Layout from "../components/Layout/Layout"
import VideoRow from "../components/VideoPlayer/VideoRow"
import Seo from "../components/Seo"
import "../styles/pages/about.scss"
const About = () => {
  const data = useWpAbout()
  // console.log(data.wpPage.about)
  return (
    <Layout isBackgroundBlue={true}>
      <Seo title="About" />
      <div className="top-clearence about-page-container gv-container">
        <div
          className="serif-size quote-wrapper"
          dangerouslySetInnerHTML={{
            __html: `${data.wpPage.about.quote}`,
          }}
        />
        <div
          className="serif-size bio-wrapper indented-wrapper"
          dangerouslySetInnerHTML={{
            __html: `${data.wpPage.about.bio}`,
          }}
        />
      </div>
      <VideoRow />
    </Layout>
  )
}

export default About
