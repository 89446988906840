import React, { useRef, useEffect } from "react"
import Header from "../Header/Header"
import "./Layout.scss"
import "../../styles/global.scss"
import "../../styles/variables.scss"
const Layout = ({ children, isBackgroundBlue, view }) => {
  const navRef = useRef(null)
  useEffect(() => {
    if (isBackgroundBlue) {
      document.body.style.backgroundColor = "#004AFF"
    } else {
      document.body.style.backgroundColor = "white"
    }
  }, [isBackgroundBlue])
  return (
    <div>
      {/* <svg
        className="defs-only"
        style={{ position: "absolute", zIndex: "-99", pointerEvents: "none" }}
      >
        <filter
          id="monochrome"
          colorInterpolationFilters="sRGB"
          x="0"
          y="0"
          height="100%"
          width="100%"
        >
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0
            0 0.29 0 0 0.1
            0 0 1 0 0
            0 0.29 1 0 0"
          ></feColorMatrix>
        </filter>
      </svg> */}
      <div
        className={`background ${
          isBackgroundBlue ? "blue-background" : "white-background"
        }`}
      ></div>
      <Header
        reference={navRef}
        isBackgroundBlue={isBackgroundBlue}
        view={view}
      />
      <main className="container">
        {/* {React.cloneElement(children, { navigationRef: navRef })} */}
        {children}
      </main>
    </div>
  )
}

export default Layout
