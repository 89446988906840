import React, { useState } from "react"
import styled from "styled-components"
import Vimeo from "@u-wave/react-vimeo"

const Section = styled.section`
  margin-top: 2px;
  background-color: black;
  position: relative;
  .video-controls-wrapper {
    position: absolute;
    z-index: 9;
    left: 10px;
    width: calc(100% - 20px);

    @media (max-width: 768px) {
      left: 6px;
      width: calc(100% - 12px);
      bottom: 6px;
    }

    bottom: 8px;
    display: flex;
    justify-content: space-between;

    button {
      padding: 0;
      border: 0;
      background: transparent;
      color: white;
      &:hover {
        cursor: pointer;
      }
      font-size: var(--sansSize);
      font-family: "SuisseIntl";
    }
  }

  .yt-link {
    position: absolute;
    right: 7px;
    top: 7px;
    z-index: 9;
    border: 0;
    background: transparent;
    color: white;
    a {
      color: white;
      text-decoration: none;
    }
  }
`
const VideoRow = () => {
  const [isMuted, setIsMuted] = useState(0)
  const [isPaused, setIsPaused] = useState(false)
  const handleAudio = () => {
    isMuted === 0 ? setIsMuted(1) : setIsMuted(0)
  }
  const handlePlay = () => {
    setIsPaused(!isPaused)
  }

  return (
    <Section>
      {/* <button className="yt-link">
        <a href="https://vimeo.com/657543736">Vimeo</a>
      </button> */}
      <Vimeo
        id="vimeo-player"
        video="657543736"
        paused={isPaused}
        background={true}
        responsive={true}
        loop={true}
        controls={true}
        dnt={true}
        volume={isMuted}
      />
      <div className="video-controls-wrapper">
        <button onClick={() => handlePlay()}>
          {isPaused ? "Play" : "Stop"}
        </button>
        <button onClick={() => handleAudio()}>
          {isMuted ? "Audio Off" : "Audio On"}
        </button>
      </div>
    </Section>
  )
}

export default VideoRow
