import { useStaticQuery, graphql } from "gatsby"
export const useWpAbout = () => {
  const data = useStaticQuery(
    graphql`
      query {
        wpPage(slug: { eq: "about" }) {
          id
          about {
            quote
            bio
          }
        }
      }
    `
  )
  return data
}
