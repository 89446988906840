import React from "react"
import { Link } from "gatsby"

import "./Header.scss"

const Header = ({ reference, isBackgroundBlue, view }) => {
  return (
    <header ref={reference} className="header-container gv-container">
      <nav className={isBackgroundBlue ? "white-links" : "black-links"}>
        <Link className="site-brand" activeClassName="active" to="/">
          Gabriele Verducci
        </Link>
        <div className="centered">
          <Link activeClassName="active" to="/about/">
            About
          </Link>
          <Link activeClassName="active" to="/archive/">
            Archive
          </Link>
          <Link activeClassName="active" to="/contacts/">
            Contacts
          </Link>
        </div>
      </nav>
    </header>
  )
}

export default Header
